<template>
  <app-menu key="Admin-menu" :items="items">
    <template #activator="{ attrs, on }">
      <v-btn class="btn-menu" v-bind="attrs" v-on="on" plain>
        {{ $t("admin-menu") }}
        <app-chevron-down />
      </v-btn>
    </template>
  </app-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AdminMenu",
  components: {
    AppBtn: () => import("@/components/core/app/Btn"),
    AppMenu: () => import("@/components/core/app/Menu"),
    AppChevronDown: () => import("@/components/core/app/ChevronDown"),
  },
  data: () => ({
    options: [
      { heading: "Users", role: ["Administrator"] },
      {
        title: "Users/Usuarios",
        to: "admin/users",
        role: ["Administrator"],
      },
      { divider: true, role: ["Administrator"] },
      {
        title: "Products/Productos",
        to: "admin/products",
        role: ["Administrator"],
      },
      { divider: true, role: ["Administrator"] },
      {
        title: "Companies/Compañias",
        to: "admin/companies",
        role: ["Administrator"],
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAuth", "user"]),
    items() {
      const filtered = this.options.filter((record) => {
        let result =
          record.role == undefined ||
          ((!record.role.some((role) => role.indexOf("*") === -1) ||
            record.role.some((role) => role === this.user.role)) &&
            !record.role.some((role) => role === "*" + this.user.role));
        return result;
      });
      return filtered;
    },
  },
};
</script>

